import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';
import { graphql } from 'gatsby';
import Modal from 'react-modal';
import classNames from 'classnames';
import { navigate } from '@reach/router';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import '../../node_modules/react-modal-video/scss/modal-video.scss';

import scrollTo from '../helpers/scrollTo';

import DataTestimonials from '../data/testimonials.json';
import DataClients from '../data/clients.json';
import DataNewsletter from '../data/newsletter.json';
import DataTechnologies from '../data/technologies.json';
import DataGraphs from '../data/graphs.json';
import DataProjects from '../data/projects.json';
import DataTimelines from '../data/timelines.json';
import DataCases from '../data/cases.json';

import Layout from '../components/Layout';
import Button from '../components/Button';
import FooterSimple from '../components/FooterSimple';
import GetBook from '../components/GetBook';
import ProjectItem from '../components/ProjectItem';
import Clients from '../components/sections/Clients';
import ContactB from '../components/sections/ContactB';
import Graph1 from '../components/sections/graph/Graph1';
import Graph2 from '../components/sections/graph/Graph2';
import Graph3 from '../components/sections/graph/Graph3';
import Graph4 from '../components/sections/graph/Graph4';
import Graph5 from '../components/sections/graph/Graph5';
import Graph6 from '../components/sections/graph/Graph6';
import RealtimeServices from '../components/RealtimeSections/RealtimeServices';
import RealtimeTimeline from '../components/RealtimeSections/RealtimeTimeline';
import RealtimeVideo from '../components/RealtimeSections/RealtimeVideo';
import CardStatic from '../components/CardStatic';
import Experience from '../components/sections/Experience';

import Bars1Src from '../img/svg/bars-5.svg';
import Bars2Src from '../img/svg/bars-6.svg';
import BookBgSrc from '../img/svg/pageSolutions/book_bg.svg';
import HeroSrc from '../img/svg/pageSolutions/hero_bg.svg';
import HeroFullSrc from '../img/svg/pageSolutions/hero_full_bg.svg';
import HeroUnderSrc from '../img/svg/pageSolutions/hero_under_bg.svg';
import ImageStarsSrc from '../img/svg/stars.svg';

import Illustration from '../img/svg/pageSolutions/hero_illustration.svg';
import IllustrationGCP from '../img/svg/pageSolutions/hero_illustration_gcp.svg';
import IllustrationDataLake from '../img/svg/pageSolutions/hero_illustration_datalake.svg';
import IllustrationBiAnalytics from '../img/svg/pageSolutions/hero_illustration_bi_analytics.svg';
import IllustrationDataScience from '../img/svg/pageSolutions/hero_illustration_dataScience.svg';
import IllustrationMLPlatform from '../img/svg/pageSolutions/hero_illustration_mlPlatform.svg';

import IllustrationCepCasesBg from '../img/svg/pageSolutions/cep_cases_bg.svg';
import IllustrationCepCasesCloud from '../img/svg/pageSolutions/cep_cases_cloud.svg';

const customModalStyles = {
  overlay: {
    backgroundColor: 'rgba(32, 39, 48, 0.5)'
  },
};

export const ServiceRealTimePageTemplate = ({ 
  hero,
  services,
  clients,
  graph,
  cases,
  book,
  project,
  video,
  timeline,
  contact,
  location,
  technologies,
  solutions,
  textAfterGraph,
  experience
}) => {

  const [isToggledModal, setToggledModal] = useState(false);
  const [isMainSVGLoaded, setIsMainSVGLoaded] = useState(false);
  const [isMainFullSVGLoaded, setIsMainFullSVGLoaded] = useState(false);
  let dt = DataTestimonials.workWithUs;

  const clientsFiltered =
    DataClients.items && DataClients.items.length && clients && clients.logos && clients.logos.length
      ? DataClients.items.filter(o => clients.logos.find(o2 => o.id == o2.value)) : null; // must be ==
  
  const testimonialFiltered =
    dt && dt.length && contact && contact.clients && contact.clients.length
      ? dt.filter(o => contact.clients.find(o2 => o.id == o2.value)) : null; // must be ==

  const dataProjects =
  DataProjects.items && DataProjects.items.length && project && project.projects && project.projects.length ?
      DataProjects.items.filter(o => project.projects.find(o2 => o.id === o2.value))
      .map(item => {
        let current = item.testimonial && item.testimonial[0] && item.testimonial[0].value;
        let testimonial =
          DataTestimonials.projects.find(o => current === o.id);

        return {...item, ...{testimonial: testimonial}}
  }) : null;

  const technologiesFiltered =
    DataTechnologies.items && DataTechnologies.items.length && technologies && technologies.logos && technologies.logos.length
      ? DataTechnologies.items.filter(o => technologies.logos.find(o2 => o.id == o2.value)) : null; // must be ==

  let graphFirst = graph && graph.item !== null && graph.item.length && graph.item[0].value;
  let graphsFiltered = DataGraphs.items.find(x => x.id === graphFirst);

  let timelineFirst = timeline && timeline.item !== null && timeline.item.length && timeline.item[0].value;
  let timelineFiltered = DataTimelines.items.find(x => x.id === timelineFirst);

  let casesFirst = cases && cases.item && cases.item.length && cases.item[0].value;
  let casesFiltered = DataCases.items.find(x => x.id === casesFirst);

  let newsletterFirst = book && book.newsletter !== null && book.newsletter.length && book.newsletter[0].value;
  let newsletterFiltered = DataNewsletter.items.find(x => x.id === newsletterFirst);

  let handleBookStatus = (param) => {
    if(param === true) {
      navigate('#book-success');
    }
  }

  let handleScrollContact = () => {
    if(location.hash === '#contact-success') {
      setTimeout(() => {
        scrollTo('realtimeContact', 'auto')
      }, 100)
    }
  }

  let illustrationHero = {};
  if (hero.illustration === 'GCP') {
    illustrationHero.src = IllustrationGCP;
  } else if (hero.illustration === 'Data Lake') {
    illustrationHero.src = IllustrationDataLake;
    illustrationHero.class = 'Realtime__hero__image--realtime';
  } else if (hero.illustration === 'BI Analytics') {
    illustrationHero.src = IllustrationBiAnalytics;
    illustrationHero.class = 'Realtime__hero__image--bianalytics';
  } else if (hero.illustration === 'Data Science') {
    illustrationHero.src = IllustrationDataScience;
    illustrationHero.class = 'Realtime__hero__image--datascience';
  } else if (hero.illustration === 'ML Platform') {
    illustrationHero.src = IllustrationMLPlatform;
  } else {
    illustrationHero.src = Illustration;
  }

  let illustrationBook = '';
  if (book.illustration === 'Data Platform') {
    illustrationBook = '/img/ebook-dataPlatform.png';
  } else {
    illustrationBook = '/img/ebook.png';
  }
  
  const classNameLogos = classNames('Clients--noPadding', { 
    'Clients--oneRow': clientsFiltered.length > 4 
  });

  const classNameHero = classNames('Realtime__hero', {
    'Realtime__hero--under': graph !== null && !graph.hidden
  });

  const classNameHeroBg = classNames('Realtime__hero__background', {
    'Realtime__hero__background--noImage': !isMainSVGLoaded,
    'Realtime__hero__background--fullImage': isMainFullSVGLoaded
  });

  useEffect(() => {
    handleScrollContact();
  });

  return (
    <div className="Realtime">
      <section className={classNameHero}>
        <div className="Layout__background">
          <SVG src={ImageStarsSrc} className="Layout__stars" />
        </div>
        
        <div className={classNameHeroBg}>
          <SVG
            src={HeroFullSrc}
            uniquifyIDs={false}
            className="Realtime__hero__background-imageFull"
            onLoad={() => setIsMainFullSVGLoaded(true)}
          />
          <SVG
            src={HeroSrc}
            uniquifyIDs={false}
            className="Realtime__hero__background-image"
            onLoad={() => setIsMainSVGLoaded(true)}
          />
          <div className="Realtime__hero__background-empty"></div>
        </div>

        <div className="Realtime__container Realtime__hero__container">
          <div className="Realtime__hero__content">
            <div className="Realtime__hero-title-wrapper">
              <SVG
                src={Bars1Src}
                className="Realtime__hero-bar Realtime__hero-bar--1"
              />
  
              <SVG
                src={Bars2Src}
                className="Realtime__hero-bar Realtime__hero-bar--2"
              />

              <h1 className="Realtime__hero__title">{hero.title}</h1>
            </div>

            <p className="Realtime__hero__text">{hero.text}</p>
            {(hero.btnText1 && hero.btnText1.length !== 0) &&
              <Button
                className="Realtime__hero__btn"
                onClick={() => scrollTo('howItWorks')}
              >
                {hero.btnText1}
              </Button>
            }
            {(hero.btnText2 && hero.btnText2.length !== 0) &&
              <Button
                onClick={() => scrollTo('readUseCase', 'smooth', -5)}
                className="Realtime__hero__btn"
                variants={['empty']}
              >
                {hero.btnText2}
              </Button>
            }
          </div>
          <div className="Realtime__hero__illustration">
            <img
              src={illustrationHero.src}
              alt="servers"
              className={classNames('Realtime__hero__image', illustrationHero.class)}
            />
          </div>
        </div>
        <div className="Realtime__clients">
          <div className="Realtime__container">
            <h5 className="Realtime__clients__title">{clients.title}</h5>
            <div className="Realtime__clients__items">
              <Clients clients={clientsFiltered} className={classNameLogos} />
            </div>
          </div>
        </div>
      </section>

      {graph && !graph.hidden &&
        <section className="Realtime__under">
          <div className="Realtime__under__background">
            <SVG
              src={HeroUnderSrc}
              uniquifyIDs={false}
              className="Realtime__under__background__img"
            />
          </div>
        </section> 
      }

      {(graph.hidden !== true && graphsFiltered.id === 'graph-1') &&
        <Graph1
          title={graph.title}
          id="howItWorks"
          items={graphsFiltered.items}
        />
      }

      {(graph.hidden !== true && graphsFiltered.id === 'graph-2') &&
        <Graph2
          title={graph.title}
          id="howItWorks"
          items={graphsFiltered.items}
        />
      }

      {(graph.hidden !== true && graphsFiltered.id === 'graph-3') &&
        <Graph3
          title={graph.title}
          id="howItWorks"
          items={graphsFiltered.items}
        />
      }

      {(graph.hidden !== true && graphsFiltered.id === 'graph-4') &&
        <Graph4
          title={graph.title}
          id="howItWorks"
          items={graphsFiltered.items}
        />
      }

      {(graph.hidden !== true && graphsFiltered.id === 'graph-5') &&
        <Graph5
          title={graph.title}
          id="howItWorks"
          items={graphsFiltered.items}
        />
      }

      {(graph.hidden !== true && graphsFiltered.id === 'graph-6') &&
        <Graph6
          title={graph.title}
          id="howItWorks"
          items={graphsFiltered.items}
        />
      }

      {textAfterGraph &&
      <div className="Realtime__textAfterGraph Realtime__background">
        <div className="Realtime__container-inner">
          <ReactMarkdown 
            className="react-markdown"
            children={textAfterGraph} 
            rehypePlugins={[rehypeRaw]} 
          />
        </div>
      </div>
      }

      {cases && !cases.hidden &&
        <section className="Realtime__cases">
          <div className="Realtime__container">
            <div className="Realtime__cases__cont">
              <h5 className="Realtime__cases__title">{cases.title}</h5>
              <p className="Realtime__cases__text">
                {cases.text}
              </p>
            </div>
          </div>
          <div className="Realtime__container Realtime__cases__container">
            <div className="Realtime__cases__container__bg">
              <SVG
                src={IllustrationCepCasesBg}
              />
            </div>
            <div className="Realtime__cases__grid">
              <div className="Realtime__cases__grid__row">
                <div className="Realtime__cases__grid__col">
                    {casesFiltered.items && casesFiltered.items.length && casesFiltered.items.map((item, i) => {
                      if(item.position == "top") {
                        return (
                          <div className="Realtime__cases__elem" key={i}>
                            <div className="Realtime__cases__elem__cont">
                              <h4 className="Realtime__cases__elem__title">{item.title}</h4>
                              <p className="Realtime__cases__elem__text">{item.text}</p>
                            </div>
                          </div>
                        )
                      }
                    })}
                </div>
              </div>
              <div className="Realtime__cases__grid__row">
                <div className="Realtime__cases__grid__col">
                  {casesFiltered.items && casesFiltered.items.length && casesFiltered.items.map((item, i) => {
                    if(item.position == "left") {
                      return (
                        <div className="Realtime__cases__elem" key={i}>
                          <div className="Realtime__cases__elem__cont">
                            <h4 className="Realtime__cases__elem__title">{item.title}</h4>
                            <p className="Realtime__cases__elem__text">{item.text}</p>
                          </div>
                        </div>
                      )
                    }
                  })}
                </div>
                <div className="Realtime__cases__grid__col">
                  
                  {cases.illustration && cases.illustration.publicURL
                    ? (
                      <SVG
                        src={cases.illustration.publicURL}
                        className="Realtime__cases__imgCloud"
                      />
                    )
                    : (
                      <SVG
                        src={IllustrationCepCasesCloud}
                        className="Realtime__cases__imgCloud"
                      />
                    )
                  }
                </div>
                <div className="Realtime__cases__grid__col">
                  {casesFiltered.items && casesFiltered.items.length && casesFiltered.items.map((item, i) => {
                    if(item.position == "right") {
                      return (
                        <div className="Realtime__cases__elem" key={i}>
                          <div className="Realtime__cases__elem__cont">
                            <h4 className="Realtime__cases__elem__title">{item.title}</h4>
                            <p className="Realtime__cases__elem__text">{item.text}</p>
                          </div>
                        </div>
                      )
                    }
                  })}
                </div>
              </div>
              <div className="Realtime__cases__grid__row">
                <div className="Realtime__cases__grid__col">
                  {casesFiltered.items && casesFiltered.items.length && casesFiltered.items.map((item, i) => {
                    if(item.position == "bottom") {
                      return (
                        <div className="Realtime__cases__elem" key={i}>
                          <div className="Realtime__cases__elem__cont">
                            <h4 className="Realtime__cases__elem__title">{item.title}</h4>
                            <p className="Realtime__cases__elem__text">{item.text}</p>
                          </div>
                        </div>
                      )
                    }
                  })}
                </div>
              </div>
            </div>
          </div>
        </section>
      }

      {(technologies && technologies.hidden !== true) &&
      <section className="Realtime__projects">
        <div className="Realtime__container">
          <h5 className="Realtime__projects__title">{technologies.title}</h5>
          <div className="Realtime__projects__items">
            <Clients
              clients={technologiesFiltered}
              className="Clients--oneRow"
              colored={true}
            />
          </div>
        </div>
      </section>
      }

      {(book && book.hidden !== true) &&
      <section className="Realtime__book" id="readUseCase">
        <div className="Realtime__book__background">
          <SVG
            src={BookBgSrc}
            uniquifyIDs={false}
            className="Realtime__book__background-image"
          />
        </div>
        <div className="Realtime__container Realtime__book__container">
          <div className="Realtime__book__content">
            <h2 className="Realtime__book__title">{book.title}</h2>
            <p className="Realtime__book__text">{book.text}</p>
            <Button
              className="Realtime__book__btn"
              onClick={() => setToggledModal(true)}
            >
              {book.btnText}
            </Button>
            <Modal
              isOpen={isToggledModal}
              onRequestClose={() => setToggledModal(false)}
              ariaHideApp={false}
              style={customModalStyles}
              className="Modal"
            >
              <GetBook 
                title={book.title}
                text={book.text}
                newsletter={newsletterFiltered && newsletterFiltered.value}
                onStatus={handleBookStatus}
              />
            </Modal>
          </div>
          <div className="Realtime__book__illustration">
            <img
              src={illustrationBook} 
              alt="ebook"
            />
          </div>
        </div>
      </section>
      }

      {dataProjects &&
      <section className="Services__projects Realtime__projects" id="projects">
        <div className="Services__container">
          <h2 className="Services__projects__title">
            <ReactMarkdown 
              children={project.title} 
              rehypePlugins={[rehypeRaw]} 
            />
          </h2>
          {dataProjects.map((item, i) => (
            <div key={i} className="Services__projects__item">
              <ProjectItem
                title={item.title}
                imageSrc={item.image}
                buttons={item.buttons}
                technologies={item.technologies}
                reference={item.testimonial}
                reverse={i % 2 === 0 ? true : false}
              >
                {item.text}
              </ProjectItem>
            </div>
          ))}
        </div>
      </section>
      }

      {(timeline && timeline.hidden !== true) &&
        <RealtimeTimeline
          heading={timeline.title}
          items={timelineFiltered.items}
        />
      }

      {graphsFiltered.id === 'graph-2'
        ? (
          <>
          {services.hidden !== true &&
            <RealtimeServices
              heading={services.heading}
              text={services.text}
              items={services.items}
            />
          }
          {(video && video.hidden !== true) &&
            <RealtimeVideo
              channel={video.channel}
              videoID={video.videoID}
              url_mp4={video.url_mp4}
              url_webm={video.url_webm}
              heading={video.title}
              text={video.text}
            />
          }
          </>
        )
        : (
          <>
          {(video && video.hidden !== true) &&
            <RealtimeVideo
              channel={video.channel}
              videoID={video.videoID}
              url_mp4={video.url_mp4}
              url_webm={video.url_webm}
              heading={video.title}
              text={video.text}
            />
          }
          {services.hidden !== true &&
            <RealtimeServices
              heading={services.heading}
              text={services.text}
              items={services.items}
            />
          }
          </>
        )
      }

      {solutions &&
      <section className="Realtime__solutions Realtime__background">
        <div className="Realtime__container">
          <div className="Realtime__solutions__items">
            {solutions.map((item, i) => (
                <CardStatic
                  key={i}
                  tagName="div"
                  className="Realtime__solutions__item"
                  title={item.title}
                >
                  {item.text}
                  <Button className="Realtime__solutions__item__btn" to={item.link}>Read more</Button>
                </CardStatic>
            ))}
          </div>
        </div>
      </section>
      }

      <Experience 
        heading={experience.heading}
        items={experience.items}
      />
      
      <ContactB
        id="realtimeContact"
        className={'Realtime__contact'} 
        title={contact.title}
        titleForm={contact.titleForm}
        text={contact.text}
        textClients={contact.textClients}
        testimonials={testimonialFiltered}
        fromPage={contact.formName}
      />

      <FooterSimple />
    </div>
  )
};

const SolutionsPost = (props) => {
  const { frontmatter } = props.data.markdownRemark;
  const mergedProps = {...frontmatter, ...{ location: props.location }};

  return (
    <Layout 
      variants={['backgrounded']} 
      meta={frontmatter.meta} 
      showContact={false} 
      showNewsletter={false} 
      showFooter={false}
    >
      <ServiceRealTimePageTemplate {...mergedProps} />
    </Layout>
  );
};

SolutionsPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default SolutionsPost;

export const solutionsPageQuery = graphql`
  query SolutionsPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        meta {
          title
          description
          image {
            publicURL
            name
          }
          hide_robot
        }

        hero {
          title
          text
          btnText1
          btnText2
          illustration
        }

        clients {
          logos {
            label
            value
          }
          title
        }

        cases {
          hidden
          title
          text
          illustration  {
            publicURL
            name
          }
          item {
            label
            value
          }
        }

        graph {
          hidden
          title
          item {
            label
            value
          }
        }

        experience {
          heading
          items {
            text
            title
            icon {
              publicURL
              name
            }
            slug
          }
        }

        technologies {
          logos {
            label
            value
          }
          title
          hidden
        }

        textAfterGraph

        book {
          hidden
          title
          text
          btnText
          newsletter {
            label
            value
          }
          illustration
        }

        project {
          title
          projects {
            value
          }
        }

        video {
          hidden
          title
          url_mp4 {
            publicURL
            name
          }
          url_webm {
            publicURL
            name
          }
          videoID
          channel
          text
        }

        timeline {
          hidden
          title
          item {
            label
            value
          }
        }

        services {
          hidden
          heading
          text
          items {
            icon {
              publicURL
              name
            }
            name
            text
          }
        }

        solutions {
          title
          text
          link
        }

        contact {
          formName
          title
          titleForm
          text
          textClients
          clients {
            label
            value
          }
        }
      }
    }
  }
`;
