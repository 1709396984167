import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import SVG from 'react-inlinesvg';

import BackgroundGraphSrc from '../../../img/svg/pageSolutions/graph_bg.svg';
import imgArrow from '../../../img/png/pageSolutions/graph/arrow.png';
import Illustration from '../../../img/png/graph/3/illustration.png';
import imgGraph1 from '../../../img/png/graph/3/a1.png';
import imgGraph2 from '../../../img/png/graph/3/a2.png';
import imgGraph3 from '../../../img/png/graph/3/a3.png';
import imgGraph4 from '../../../img/png/graph/3/a4.png';
import imgGraph7 from '../../../img/png/graph/3/a7.png';
import imgGraph8 from '../../../img/png/graph/3/a8.png';
import imgGraph9 from '../../../img/png/graph/3/a9.png';
import imgGraph10 from '../../../img/png/graph/3/a10.png';
import imgGraph11 from '../../../img/png/graph/3/a11.png';
import imgGraph12 from '../../../img/png/graph/3/a12.png';
import imgGraph13 from '../../../img/png/graph/3/a13.png';

class Graph3 extends React.Component {

  render() {
    const { className = '', id = '', title, items } = this.props;

    return (
      <section className={classNames('Graph Graph--3', className)} id={id}>
        <div className="Graph__desktop">
          <div className="Graph__background">
            <SVG
              src={BackgroundGraphSrc}
              uniquifyIDs={false}
              className="Graph__background-image"
            />
            <div className="Graph__container">
              <h2 className="Graph__title">{title}</h2>
              {/* <div className="Graph__arrow">
                <img src={imgArrow} alt="servers" />
              </div> */}
              <div className="Graph__grid">
                <div className="Graph__grid__col">
                  <div className="Graph__grid__elem">
                    <img src={imgGraph1} alt="servers" />
                    <div className="Graph__grid__elem__text Graph__grid__elem__text--right">
                      <h4>{items[0].title}</h4>
                      <ReactMarkdown rehypePlugins={[rehypeRaw]} children={items[0].text} />
                    </div>
                  </div>
                </div>
                <div className="Graph__grid__col">
                  <div className="Graph__grid__rowA">
                    <div className="Graph__grid__colA">
                      <div className="Graph__grid__elem">
                        <img src={imgGraph2} alt="servers" />
                        <div className="Graph__grid__elem__text Graph__grid__elem__text--right">
                          <h4>{items[1].title}</h4>
                          <ReactMarkdown rehypePlugins={[rehypeRaw]} children={items[1].text} />
                        </div>
                      </div>
                    </div>
                    <div className="Graph__grid__colA">
                      <div className="Graph__grid__elem">
                        <img src={imgGraph3} alt="servers" />
                        <div className="Graph__grid__elem__text Graph__grid__elem__text--right">
                          <h4>{items[2].title}</h4>
                          <ReactMarkdown rehypePlugins={[rehypeRaw]} children={items[2].text} />
                        </div>
                      </div>
                      <div className="Graph__grid__elem">
                        <img src={imgGraph4} alt="servers" />
                        <div className="Graph__grid__elem__text Graph__grid__elem__text--right">
                          <h4>{items[3].title}</h4>
                          <ReactMarkdown rehypePlugins={[rehypeRaw]} children={items[3].text} />
                        </div>
                      </div>
                    </div>
                    <div className="Graph__grid__colA">
                      <div className="Graph__grid__elem">
                        <img src={imgGraph7} alt="servers" />
                        <div className="Graph__grid__elem__text Graph__grid__elem__text--left">
                          <h4>{items[4].title}</h4>
                          <ReactMarkdown rehypePlugins={[rehypeRaw]} children={items[4].text} />
                        </div>
                      </div>
                    </div>
                    <div className="Graph__grid__colA">
                      <div className="Graph__grid__elem">
                        <img src={imgGraph8} alt="servers" />
                        <div className="Graph__grid__elem__text Graph__grid__elem__text--left">
                          <h4>{items[5].title}</h4>
                          <ReactMarkdown rehypePlugins={[rehypeRaw]} children={items[5].text} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="Graph__grid__rowE">
                    <div className="Graph__grid__colE">
                      <div className="Graph__grid__elem">
                        <img src={imgGraph10} alt="servers" />
                        <div className="Graph__grid__elem__text Graph__grid__elem__text--right">
                          <h4>{items[6].title}</h4>
                          <ReactMarkdown rehypePlugins={[rehypeRaw]} children={items[6].text} />
                        </div>
                      </div>
                    </div>
                    <div className="Graph__grid__colE">
                      <div className="Graph__grid__elem">
                        <img src={imgGraph11} alt="servers" />
                        <div className="Graph__grid__elem__text Graph__grid__elem__text--right">
                          <h4>{items[7].title}</h4>
                          <ReactMarkdown rehypePlugins={[rehypeRaw]} children={items[7].text} />
                        </div>
                      </div>
                    </div>
                    <div className="Graph__grid__colE">
                      <div className="Graph__grid__elem">
                        <img src={imgGraph12} alt="servers" />
                        <div className="Graph__grid__elem__text Graph__grid__elem__text--left">
                          <h4>{items[8].title}</h4>
                          <ReactMarkdown rehypePlugins={[rehypeRaw]} children={items[8].text} />
                        </div>
                      </div>
                    </div>
                    <div className="Graph__grid__colE">
                      <div className="Graph__grid__elem">
                        <img src={imgGraph13} alt="servers" />
                        <div className="Graph__grid__elem__text Graph__grid__elem__text--left">
                          <h4>{items[9].title}</h4>
                          <ReactMarkdown rehypePlugins={[rehypeRaw]} children={items[9].text} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="Graph__grid__col">
                  <div className="Graph__grid__elem">
                    <img src={imgGraph9} alt="servers" />
                    <div className="Graph__grid__elem__text Graph__grid__elem__text--left">
                      <h4>{items[10].title}</h4>
                      <ReactMarkdown rehypePlugins={[rehypeRaw]} children={items[10].text} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <div className="Graph__mobile">
          <div className="Graph__background">
            <div className="Realtime__container Graph__container"> 
              <h2 className="Graph__title">{title}</h2>
              <img src={Illustration} alt="Graph"/>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

Graph3.propTypes = {
  items: PropTypes.array
};

export default Graph3;
