import React, { useState, useEffect } from 'react';
import SVG from 'react-inlinesvg';
import ModalVideo from 'react-modal-video';

import VideoBgSrc from '../../img/svg/pageSolutions/video_bg.svg';
import ArrowSrc from '../../img/svg/pageSolutions/arrow_long.svg';
import IconPlay from '../../../static/img/icon-play.svg';
import Bars3Src from '../../img/svg/bars-7.svg';
import Bars4Src from '../../img/svg/bars-8.svg';

function RealtimeVideo({ heading, videoID, channel, url_mp4, url_webm, text }) {

  const [isToggled, setToggled] = useState(false);
  const videoExist = ((url_mp4 && url_mp4.publicURL.length > 0) || (url_webm && url_webm.publicURL.length > 0))

  return (
    <section className="Realtime__video">
      <div className="Realtime__video__background">
        <SVG
          src={VideoBgSrc}
          uniquifyIDs={false}
          className="Realtime__video__background-image"
        />
      </div>
      <div className="Realtime__container">
        <div className="Realtime__video__container">
          <div className="Realtime__video-title-wrapper">
            <SVG
              src={Bars3Src}
              className="Realtime__video-bar Realtime__video-bar--1"
            />

            <SVG
              src={Bars4Src}
              className="Realtime__video-bar Realtime__video-bar--2"
            />

            <h3 className="Realtime__video__title">
              {heading}
            </h3>
          </div>
          <div className="Realtime__video-text">
            {text}
          </div>
          <div className="Realtime__video__arrow">
            <SVG
              src={ArrowSrc}
              uniquifyIDs={false}
              className="Realtime__video__arrow__img"
            />
          </div>
        </div>
        <div className="Realtime__video__item" onClick={() => setToggled(!isToggled)}>
          {videoExist &&
          <video muted="muted" loop="loop" autoPlay="autoplay" poster="">
            {url_mp4.publicURL.length > 0 &&
              <source src={url_mp4.publicURL} type="video/mp4" />
            }
            {url_webm.publicURL.length > 0 &&
            <source src={url_webm.publicURL} type="video/webm" />
            }
            Your browser does not support the video tag
          </video>
          }
          <SVG
            src={IconPlay}
            uniquifyIDs={false}
            className="Realtime__video__item__btn"
          />
        </div>
        {videoID &&
          <ModalVideo 
            channel={channel && channel.length ? channel : 'youtube'}
            isOpen={isToggled} 
            videoId={videoID} 
            onClose={() => setToggled(false)} 
          />
        }
      </div>
    </section>
  );
}

export default RealtimeVideo;
