import React from 'react';
import SVG from 'react-inlinesvg';

import Card from '../Card';
import CardStatic from '../CardStatic';
import ServicesBgSrc from '../../img/svg/pageSolutions/services_bg.svg';
import SnakeBgSrc from '../../img/svg/pageSolutions/snake-lines-green.svg';

function RealtimeServices({ heading, text, items = [] }) {

  return (
    <section className="Realtime__services">
      <div className="Realtime__services__background">
        <SVG
          src={ServicesBgSrc}
          uniquifyIDs={false}
          className="Realtime__services__background-image"
        />
      </div>

      <div className="Realtime__services__container">
        <div className="Realtime__services__content">
          <h2 className="Realtime__services__title">{heading}</h2>

          <p className="Realtime__services__text">
            {text}
          </p>
        </div>

        <div className="Realtime__services__cards">
          <SVG
            src={SnakeBgSrc}
            uniquifyIDs={false}
            className="Realtime__services__cards__bg"
          />
          <ul className="Realtime__services__items">
            {items.map((item, i) => {
              return (
                <CardStatic
                  key={i}
                  tagName="li"
                  className="Realtime__services__item"
                  iconSrc={item.icon && item.icon.publicURL}
                  title={item.name}
                />
              )
            })}
          </ul>
        </div>
      </div>
    </section>
  );
}

export default RealtimeServices;
