import React, { useState } from 'react';
import SwiperCore, { Navigation} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { max } from 'lodash';
import classNames from 'classnames';

import 'swiper/swiper.scss';

SwiperCore.use([Navigation]);

const Timeline = ({className, data}) => {

  const [offset, setOffset] = useState(0);

  const findMaxHeightElement = (swiper) => {
    let arr = [];
    swiper.slides.each(function(e) {
      if(e.classList.contains('Timeline__slide--even')) {
        let selector = e.querySelector(".Timeline__item__container");
        arr.push(selector.clientHeight);
      }
    });

    return max(arr);
  }

  const params = {
    spaceBetween: 0,
    slidesPerView: 1,
    grabCursor: true,
    loop: true,
    loopedSlides: 2,
    loopAdditionalSlides: 100,
    loopFillGroupWithBlank: true,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false
    },
    breakpoints: {
      600: {
        slidesPerView: 3
      },
      978: {
        slidesPerView: 4
      },
      1200: {
        slidesPerView: 5
      }
    },
    onInit: (swiper) => {
      setTimeout(() => {
        let result = findMaxHeightElement(swiper);
        setOffset(result);
      }, 500);
    },
    onResize: (swiper) => {
      let result = findMaxHeightElement(swiper);
      setOffset(result);
    }
  }

  return (
    <div className={classNames(className, 'Timeline')} style={{paddingTop: offset}}>
      <Swiper {...params} navigation>
        {data.map((item, i) => {
          let classTimeline = i % 2 == 0 ? 'Timeline__slide--even' : '';

          return (
            <SwiperSlide className={classNames(classTimeline, 'Timeline__slide')} key={i}>
              <div className="Timeline__item">
                <div className="Timeline__item__dot"></div>
                <div className="Timeline__item__container">
                  <div className="Timeline__item__date">{item.year}</div>
                  <div className="Timeline__item__text">{item.text}</div>
                  {(item.image) &&
                    <img src={item.image} alt="" className="Timeline__item__logo"/>
                  }
                </div>
              </div>
            </SwiperSlide>
          )
        })}
        {/* empty slide at the end */}
        <SwiperSlide className='Timeline__slide'></SwiperSlide>
      </Swiper>
    </div>
  )
}

export default Timeline;
