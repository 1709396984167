import React from 'react';
import SVG from 'react-inlinesvg';

import Timeline from '../Timeline';

import Bars3Src from '../../img/svg/bars-7.svg';
import Bars4Src from '../../img/svg/bars-8.svg';

function RealtimeTimeline({ heading, items = [] }) {

  return (
    <section className="Realtime__timeline">
      <div className="Realtime__container">
        <div className="Realtime__timeline-title-wrapper">
          <h3 className="Realtime__timeline__title">{heading}</h3>
          <SVG
            src={Bars3Src}
            className="Realtime__timeline-bar Realtime__timeline-bar--1"
          />
          <SVG
            src={Bars4Src}
            className="Realtime__timeline-bar Realtime__timeline-bar--2"
          />
        </div>
      </div>
      <Timeline 
        data={items}
        className="Timeline--light"
      />
    </section>
  );
}

export default RealtimeTimeline;
