import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import SVG from 'react-inlinesvg';

import BackgroundGraphSrc from '../../../img/svg/pageSolutions/graph_bg.svg';

import imgArrow from '../../../img/png/pageSolutions/graph/arrow.png';
import imgBg from '../../../img/png/graph/2/bg.png';
import Illustration from '../../../img/png/graph/2/illustration.png';
import imgGraph1 from '../../../img/png/graph/2/a1.png';
import imgGraph2 from '../../../img/png/graph/2/a2.png';
import imgGraph3 from '../../../img/png/graph/2/a3.png';
import imgGraph4 from '../../../img/png/graph/2/a4.png';
import imgGraph5 from '../../../img/png/graph/2/a5.png';
import imgGraph6 from '../../../img/png/graph/2/a6.png';
import imgGraph7 from '../../../img/png/graph/2/a7.png';

class Graph2 extends React.Component {

  render() {
    const { className = '', id = '', title, items } = this.props;

    return (
      <section className={classNames('Graph Graph--2', className)} id={id}>
        <div className="Graph__desktop">
          <div className="Graph__background">
            <SVG
              src={BackgroundGraphSrc}
              uniquifyIDs={false}
              className="Graph__background-image"
            />
            <div className="Graph__container">
              <h2 className="Graph__title">{title}</h2>
              {/* <div className="Graph__arrow">
                <img src={imgArrow} alt="servers" />
              </div> */}
              <div className="Graph__grid">
                <div className="Graph__grid__bg">
                  <img src={imgBg} alt="servers" />
                </div>
                <div className="Graph__grid__overBg">
                  <div className="Graph__grid__elem Graph__grid__elem--1">
                    <img src={imgGraph1} alt="servers" />
                    <div className="Graph__grid__elem__text Graph__grid__elem__text--right">
                      <h4>{items[0].title}</h4>
                      <ReactMarkdown rehypePlugins={[rehypeRaw]} children={items[0].text} />
                    </div>
                  </div>
                  <div className="Graph__grid__elem Graph__grid__elem--2">
                    <img src={imgGraph2} alt="servers" />
                    <div className="Graph__grid__elem__text Graph__grid__elem__text--left">
                      <h4>{items[1].title}</h4>
                      <ReactMarkdown rehypePlugins={[rehypeRaw]} children={items[1].text} />
                    </div>
                  </div>
                  <div className="Graph__grid__elem Graph__grid__elem--3">
                    <img src={imgGraph3} alt="servers" />
                    <div className="Graph__grid__elem__text Graph__grid__elem__text--right">
                      <h4>{items[2].title}</h4>
                      <ReactMarkdown rehypePlugins={[rehypeRaw]} children={items[2].text} />
                    </div>
                  </div>
                  <div className="Graph__grid__elem Graph__grid__elem--4">
                    <img src={imgGraph4} alt="servers" />
                    <div className="Graph__grid__elem__text">
                      <h4>{items[3].title}</h4>
                      <ReactMarkdown rehypePlugins={[rehypeRaw]} children={items[3].text} />
                    </div>
                  </div>
                  <div className="Graph__grid__elem Graph__grid__elem--5">
                    <img src={imgGraph5} alt="servers" />
                    <div className="Graph__grid__elem__text Graph__grid__elem__text--left">
                      <h4>{items[4].title}</h4>
                      <ReactMarkdown rehypePlugins={[rehypeRaw]} children={items[4].text} />
                    </div>
                  </div>
                  <div className="Graph__grid__elem Graph__grid__elem--6">
                    <img src={imgGraph6} alt="servers" />
                    <div className="Graph__grid__elem__text Graph__grid__elem__text--right">
                      <h4>{items[5].title}</h4>
                      <ReactMarkdown rehypePlugins={[rehypeRaw]} children={items[5].text} />
                    </div>
                  </div>
                  <div className="Graph__grid__elem Graph__grid__elem--7">
                    <img src={imgGraph7} alt="servers" />
                    <div className="Graph__grid__elem__text Graph__grid__elem__text--left">
                      <h4>{items[6].title}</h4>
                      <ReactMarkdown rehypePlugins={[rehypeRaw]} children={items[6].text} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <div className="Graph__mobile">
          <div className="Graph__background">
            <div className="Realtime__container Graph__container"> 
              <h2 className="Graph__title">{title}</h2>
              <img src={Illustration} alt="Graph"/>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

Graph2.propTypes = {
  items: PropTypes.array
};

export default Graph2;
